<template>
  <div class="clients-container">
    <admin-header-vue
      v-if="showCreate"
      alias="Role"
      @onCreate="onCreate"
      pageName="" />
    <div class="user-table">
      <table-vue
        :labels="labels"
        :data="roleList"
        :dateFormat="dateFormat"
        :sortObj="sort"
        :totalCount="totalCount"
        :currentPage="currentPage"
        :limit="limit"
        :sortValidation="sortValidation"
        :titleData="'role'"
        :showEditAction="showEdit"
        :showDeleteAction="showDelete"
        :showAction="showEdit || showDelete"
        @searchText="searchText"
        @onUpdate="onUpdate"
        @onClick="onClick"
        @onChangeSort="onChangeSort"
        @updatePage="updatePage"
        @updateLimit="updateLimit" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import tableVue from '../../../components/table.vue'
import AdminHeaderVue from '../../../components/AdminHeader.vue'
import { metaTitle } from '../../../../../constant/data'

export default {
  name: 'Roles',
  components: {
    tableVue,
    AdminHeaderVue
  },
  metaInfo () {
    return {
      title: metaTitle.role
    }
  },
  data () {
    return {
      dateFormat: ['created_at', 'updated_at'],
      labels: [{ name: 'Role Id', alias: 'r_id' }, { name: 'Role Name', alias: 'role' }, { name: 'Created At', alias: 'created_at' }, { name: 'Updated At', alias: 'updated_at' }],
      sortValidation: ['r_id', 'role', 'created_at', 'updated_at'],
      search: '',
      sort: { by: 'asc', field: 'r_id' }
    }
  },
  computed: {
    ...mapState('role', ['roleList', 'totalCount']),
    ...mapState('adminCommon', ['tableSelectionState']),
    ...mapState('user', ['userDetails']),
    showCreate () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.write)].length) {
        return true
      }
      return false
    },
    showEdit () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.update)].length) {
        return true
      }
      return false
    },
    showDelete () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.delete)].length) {
        return true
      }
      return false
    },
    limit: {
      get () {
        return this.tableSelectionState ? this.tableSelectionState.role.limit : 10
      },
      set (newLimit) {
        this.tableSelectionState.role.limit = newLimit
      }
    },
    currentPage: {
      get () {
        return this.tableSelectionState ? this.tableSelectionState.role.currentPage : 1
      },
      set (newPage) {
        this.tableSelectionState.role.currentPage = newPage
      }
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions('role', ['getRoleList']),
    labelsFun () {
      return this.roleList.length ? Object.keys(this.roleList[0]) : []
    },
    onClick (data) {
    },
    onUpdate (data) {
      this.$router.push(`roles-and-permissions/update/${data.r_id}`)
    },
    onCreate () {
      this.$router.push('roles-and-permissions/add')
    },
    onChangeSort (type) {
      this.sort = type
      this.getData()
    },
    updatePage (data) {
      this.currentPage = data
      this.getData()
    },
    updateLimit (data) {
      this.limit = data
      this.getData()
    },
    searchText (text) {
      this.search = text
      if (this.search !== null) {
        this.currentPage = 1
        this.getData()
      }
    },
    getData () {
      this.getRoleList({ limit: this.limit, skip: (this.currentPage - 1) * this.limit, orderBy: this.sort, search: this.search })
    }
  }
}
</script>

<style lang="scss" scoped>
.clients-container {
  width: 100%;
  margin: 20px 0px;
  border-radius: 4px;
  background: var(--primary);
}
</style>
